import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../footer/footer.scss"


const Footer = () => {

    const { t, i18n } = useTranslation();

    return (
        <footer className="footer">
            <div className="container">
                <div className="top">
                    <div className="logo">
                        <img src="/assets/logo.svg" alt="Logo" />
                    </div>
                    <div className="links">
                        <span>{t("impressum.adresse")}</span>
                        <span>
                            <Link to="tel:+393470183939">+39 347 018 3939</Link>
                        </span>
                        <span>
                            <Link to="mailto:info@oberkroesser.com">info@oberkroesser.com</Link>
                        </span>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <Link to={"https://www.roterhahn.it/de/urlaub-auf-dem-bauernhof/suedtirol/ferienwohnungen-und-zimmer/oberkroesser-st-lorenzen+5969-1"} target="blank">
                    <img src="/assets/logoRoterHahn.png" alt="Roter Hahn" />
                </Link>
                <Link to={"https://www.suedtirol.info/"} target="blank">
                    <img src="https://storage.googleapis.com/sites.hgv.it/media/suedtirol.svg" alt="Südtirol" />
                </Link>
                <Link to={"https://www.bruneck.com/de/unterkuenfte/oberkrsserhof_121236"} target="blank">
                    <img src="https://storage.googleapis.com/sites.hgv.it/media/kronplatz.svg" alt="Kronplatz" />
                </Link>
            </div>
            <div className="wrapperLinks">
                <span>© {new Date().getFullYear()} {t("site.pageName")}</span>
                <span>{t("impressum.mwstnr")}</span>
                <Link to={`/${i18n.language}/impressum`}>{t("site.impressum")}</Link>
                <Link to={`/${i18n.language}/datenschutz`}>{t("site.datenschutz")}</Link>
            </div>
        </footer>
    )
};

export default Footer