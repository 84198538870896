import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../logo.svg';
import "../navbar/navbar.scss"

const Navbar = () => {
    const [isActive, setIsActive] = useState(false);
    const [isScroll, setIsScroll] = useState(false);
    const [isActiveLanguage, setIsActiveLanguage] = useState(false)

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const changeLanguage = (lng) => {
        const { pathname, search, hash } = window.location;

        const currentLang = pathname.split('/')[1];
        const newPathname = pathname.replace(`/${currentLang}/`, `/${lng}/`);

        navigate(newPathname + search + hash);
        i18n.changeLanguage(lng);

        setIsActive(false);
        setIsActiveLanguage(false)
    };


    const handleClick = event => {
        // 👇️ toggle isActive state on click
        setIsActive(current => !current);
    };

    const handleClickLanguage = event => {
        // 👇️ toggle isActive state on click
        setIsActiveLanguage(current => !current);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 1) {
                setIsScroll(true);
            } else {
                setIsScroll(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className="nav">
            <div className={isActive ? "navbar active" : isScroll ? "navbar scroll" : "navbar"}>
                <div className="container">
                    <div className="left">
                        <div className={isActive ? "openNav active" : "openNav"} onClick={handleClick}>
                            <div className="navLines"></div>
                            <div className="navLines"></div>
                        </div>
                    </div>
                    <Link to={`/${i18n.language}/`} className="logoLink">
                        <Logo style={{ fill: "inherit"}} className="logo"/>
                    </Link>
                    <div className="right">
                        <div className={isActive ? "links active" : "links"}>
                            <Link to={`/${i18n.language}/anfragen`} className="call">
                                <span>{t("site.anfragen")}</span>
                            </Link>
                            <Link to={`/${i18n.language}/kontakt`} className="call">
                                <span>{t("site.kontakt")}</span>
                            </Link>
                        </div>
                        <div className="language">
                            <div className={isActiveLanguage ? "boxLanguage active" : "boxLanguage"}>
                                <button
                                    onClick={handleClickLanguage}
                                    className={i18n.language === 'de' ? 'active' : ''}
                                >{i18n.language}</button>
                                {isActiveLanguage &&
                                    <div className={isActiveLanguage ? "expand active" : "expand"}>
                                        {i18n.language !== 'it' && <button
                                            onClick={() => changeLanguage('it')}
                                            className={i18n.language === 'it' ? 'active' : ''}
                                        >it</button>}
                                        {i18n.language !== 'de' && <button
                                            onClick={() => changeLanguage('de')}
                                            className={i18n.language === 'de' ? 'active' : ''}
                                        >de</button>}
                                        {i18n.language !== 'en' && <button
                                            onClick={() => changeLanguage('en')}
                                            className={i18n.language === 'en' ? 'active' : ''}
                                        >en</button>}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={isActive ? "navModal open" : "navModal"}>
                <div className="container">
                    <div className="left">
                        <Link to={`/${i18n.language}/`} onClick={handleClick}>
                            <h1>{t("site.home")}</h1>
                        </Link>
                        <Link to={`/${i18n.language}/ferienwohnung`} onClick={handleClick}>
                            <h1>{t("site.ferienwohnung")}</h1>
                        </Link>
                        <Link to={`/${i18n.language}/entdecken`} onClick={handleClick}>
                            <h1>{t("site.entdecken")}</h1>
                        </Link>
                        <Link to={`/${i18n.language}/kontakt`} onClick={handleClick}>
                            <h1>{t("site.kontakt")}</h1>
                        </Link>
                        <Link to={`/${i18n.language}/anfragen`} onClick={handleClick}>
                            <h1>{t("site.anfragen")}</h1>
                        </Link>
                    </div>
                </div>
            </div>
        </div>

    )
};

export default Navbar